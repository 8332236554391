import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';
import {Overlay} from 'components/customer/Product/QuickAdd/Overlay';
import {Controls} from 'components/customer/Product/QuickAdd/Controls';
import {ItemType} from 'shared/components/Product/Item';
import {useAddProductManually} from 'shared/helpers/addProductManually';
import {useJobContext, useNotificationContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {invalidateProductAndCost} from 'components/customer/Product/store/productApi';
import {useAppDispatch} from 'store/customer';
import {genericMessageHandler} from 'shared/helpers';
import {useNavigate} from 'react-router-dom';

interface QuickAddProps {
    open: boolean;
    onClickHandler: () => void;
    item?: ItemType;
}

type SetState<T> = Dispatch<SetStateAction<T>>;

interface QuickAddInterface extends QuickAddProps {
    quantity?: number;
    setQuantity?: SetState<number>;
    width?: number;
    setWidth?: SetState<number>;
    addProduct?: () => Promise<void>;
    loading?: boolean;
}

export const QuickAddContext =
    React.createContext<QuickAddInterface>(undefined);

export const useQuickAddContext = () => useContext(QuickAddContext);

export const QuickAdd = ({item, open, onClickHandler}: QuickAddProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [quantity, setQuantity] = useState(1);
    const [width, setWidth] = useState(item.defaultWidth);
    const [loading, setLoading] = useState(false);

    const {job, room} = useJobContext() as PartialJob;
    const {notify} = useNotificationContext();
    const addProductManually = useAddProductManually();

    const addProduct = useCallback(async () => {
        setLoading(true);
        try {
            await addProductManually({
                product: {id: item.id},
                room,
                width,
                quantity,
            });
            dispatch(invalidateProductAndCost());

            genericMessageHandler(
                notify,
                {message: 'Product saved'},
                'success'
            );
        } catch (e) {
            let url = `/v2/job/${job.displayId}/room/${room.id}/product`;
            const params = new URLSearchParams({
                product: item.id.toString(),
                width: width.toString(),
                quantity: quantity.toString(),
                validate: '1',
            });
            url += `?${params.toString()}`;

            navigate(url);
        } finally {
            setLoading(false);
        }
    }, [job, quantity, width, item, addProductManually]);

    return (
        <QuickAddContext.Provider
            value={{
                item,
                loading,
                open,
                onClickHandler,
                quantity,
                setQuantity,
                width,
                setWidth,
                addProduct,
            }}>
            <div>
                <Overlay />
                <Controls />
            </div>
        </QuickAddContext.Provider>
    );
};
