import React, {useCallback} from 'react';
import {Icon} from 'shared/helpers';
import styled, {useTheme} from 'styled-components';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';
import {FormControl} from 'react-bootstrap';

export const NumberSpinner = () => {
    const {quantity, setQuantity, item} = useQuickAddContext();
    const theme = useTheme();

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(event.target.value);

            if (!isNaN(newValue)) {
                if (newValue > 0) {
                    setQuantity(newValue);
                }
            }
        },
        [setQuantity]
    );

    const decrement = useCallback(() => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }, [quantity, setQuantity]);

    const increment = useCallback(() => {
        setQuantity(quantity + 1);
    }, [quantity, setQuantity]);

    return (
        <Container>
            <Buttons
                data-cy="decrement-button"
                iconName="Button-Minus.svg"
                color={theme.colors.secondary.main}
                onClick={decrement}
            />
            <Number
                data-cy={item ? `quantity-${item.id}` : 'quantity'}
                type="number"
                value={quantity}
                min={1}
                max={9999}
                required
                onChange={changeHandler}
            />
            <Buttons
                data-cy="increment-button"
                iconName="Button-Plus.svg"
                color={theme.colors.secondary.main}
                onClick={increment}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    gap: 5px;
`;

const Buttons = styled((props) => <Icon {...props} />)`
    width: 15px;
    cursor: pointer;
`;

export const Number = styled(FormControl)`
    border: 2px solid ${({theme}) => theme.colors.secondary.main};
    border-radius: 5px;
    padding: 0 5px;
    height: 100%;
    font-size: 0.8em;
    text-align: center;
    min-width: 33px;

    :focus,
    :active {
        border-color: ${({theme}) => theme.colors.secondary.main};
    }
`;
