import React, {useCallback} from 'react';
import {Number} from 'components/customer/Product/QuickAdd/NumberSpinner';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';

export const Width = () => {
    const {width, setWidth, item} = useQuickAddContext();

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(event.target.value);

            if (!isNaN(newValue)) {
                if (newValue > 0) {
                    setWidth(newValue);
                }
            }
        },
        [setWidth]
    );

    return (
        <Number
            value={width}
            onChange={changeHandler}
            data-cy={item ? `width-${item.id}` : 'width'}
        />
    );
};
