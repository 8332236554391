import React from 'react';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';
import styled, {useTheme} from 'styled-components';
import {Icon} from 'shared/helpers';

export const Overlay = () => {
    const {open, loading, onClickHandler} = useQuickAddContext();
    const theme = useTheme();

    if (open) {
        return (
            <Container onClick={onClickHandler}>
                <ImageAndText>
                    {loading ? (
                        <CenterIcon
                            iconName="tail-spin.svg"
                            color={theme.colors.primary.main}
                        />
                    ) : (
                        <>
                            <CenterIcon iconName="search-white.svg" />
                            CLICK to CUSTOMISE
                        </>
                    )}
                </ImageAndText>
                <SubText>
                    <div>
                        or <strong>quickly add</strong> below
                    </div>
                    <ChevronContainer>
                        <ChevronDown iconName="ChevronDownWhite.svg" />
                    </ChevronContainer>
                </SubText>
            </Container>
        );
    }
};

const SubText = styled.div`
    font-size: 0.8em;
    background: rgb(var(--secondary_colour));
    color: white;
    padding-bottom: 5px;
`;

const ChevronDown = styled((props) => <Icon {...props} />)`
    width: 10px;
`;

const ChevronContainer = styled.div`
    line-height: 1px;
`;

const ImageAndText = styled.div`
    flex: 1;
    align-content: center;

    color: white;
    font-weight: bold;
`;

const CenterIcon = styled((props) => <Icon {...props} />)`
    width: 3.5vw;
    display: block;
    margin: 0 auto;
`;

const Container = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 35px;
    background: rgba(var(--secondary_colour), 0.6);
    z-index: 10;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    overflow: clip;
    cursor: pointer;
`;
