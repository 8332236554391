// @flow
import React, {useMemo, useEffect} from 'react';
import {FIELD_TYPES, Field} from 'shared';
import {useFormikContext} from 'formik';
import {Form, Row, Col} from 'react-bootstrap';
import {ColorSwatchSelected} from 'components/customer/Room/ColorSwatches';
import {MaterialSimplified} from 'components/customer/Product';
import {useProductContext} from 'contexts';
import {Loader, getDoorInfo} from 'shared/helpers';
import {getVisibility} from 'shared/components/Forms/Field';
import Excel from 'shared/Excel';
import {ColorSwatchContainer} from 'components/customer/Room/ColourSearch';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';

type MaterialDefaultsType = {
    fields: Array<any>,
    hasPreview: boolean,
};

export const MaterialDefaults = ({
    fields,
    hasPreview,
}: MaterialDefaultsType): React$Element<any> => {
    const {values} = useFormikContext();
    const {
        materialLoading,
        formStructure,
        setMaterialFace,
        productDataStore,
        getMaterialOptions,
    } = useProductContext();

    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();

    useEffect(() => {
        setMaterialFace(productDataStore.current.include_drawer_faces == 1);
    }, []);

    const hasDoor = useMemo(
        () => getDoorInfo({structure: formStructure}),
        [formStructure]
    );

    const [exteriorColorThumbnail, exteriorEdgeColorThumbnail] = useMemo(() => {
        return [
            <ColorSwatchContainer $isMobile={isSmallDevice || isTabletSize}>
                <ColorSwatchSelected
                    isExterior={true}
                    horizontal={values.hor_grain_ext}
                    key={0}
                    smallThumbnail={hasPreview}
                />
            </ColorSwatchContainer>,
            <ColorSwatchSelected
                isExterior={true}
                horizontal={values.hor_grain_ext}
                key={1}
                isEdge={true}
                smallThumbnail={hasPreview}
            />,
        ];
    }, [values.hor_grain_ext, hasPreview]);

    const [carcaseColorThumbnail, carcaseEdgeColorThumbnail] = useMemo(() => {
        return [
            <ColorSwatchContainer $isMobile={isSmallDevice || isTabletSize}>
                <ColorSwatchSelected
                    isExterior={false}
                    horizontal={values.hor_grain_carc}
                    key={0}
                    smallThumbnail={hasPreview}
                />
            </ColorSwatchContainer>,
            <ColorSwatchSelected
                isExterior={false}
                horizontal={values.hor_grain_carc}
                key={1}
                isEdge={true}
                smallThumbnail={hasPreview}
            />,
        ];
    }, [values.hor_grain_carc, hasPreview]);

    return (
        <>
            <Loader loader={materialLoading} />
            {fields.map((field, index) => {
                const isExterior = field.name.indexOf('ext') > -1;
                let header = '';

                // Smash a header in before the first line and before the carcase material set
                if (
                    index == 0 ||
                    (field.type == FIELD_TYPES.MATERIAL && !isExterior)
                ) {
                    header = (
                        <Row>
                            <Col>
                                <h3
                                    className="notes-and-variations"
                                    style={{margin: 0}}>
                                    {isExterior ? 'Exterior' : 'Carcase'}
                                </h3>
                            </Col>
                        </Row>
                    );
                }

                if (
                    field.type != FIELD_TYPES.MATERIAL &&
                    field.type != 'edge_material' &&
                    (!field?.options.hasOwnProperty('visible') ||
                        (field?.options.hasOwnProperty('visible') &&
                            Excel.calculate(field.options.visible, values)))
                ) {
                    return (
                        <React.Fragment key={field.name}>
                            {header}
                            <section
                                style={{
                                    marginTop: '15px',
                                }}>
                                <Form.Group
                                    as={Row}
                                    style={{
                                        marginBottom:
                                            field.type != FIELD_TYPES.RADIO
                                                ? '10px'
                                                : '0px',
                                    }}>
                                    <Col
                                        md={
                                            isTabletSize
                                                ? 12
                                                : {offset: 2, span: 10}
                                        }>
                                        <Field
                                            field={field}
                                            type={field.type}
                                            fieldSpan={12}
                                            formControlSpan={
                                                isSmallDevice || isTabletSize
                                                    ? 6
                                                    : 4
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                            </section>
                        </React.Fragment>
                    );
                }

                const isVisible = getVisibility(
                    field,
                    null, // Assuming this is never used
                    values,
                    {}, // Assuming there's never an index
                    getMaterialOptions()
                );

                const isEdgeMaterial = field.name.indexOf('edge') > -1;

                let thumbnail: React$Element<any>;

                if (isExterior) {
                    if (isEdgeMaterial) {
                        thumbnail = exteriorEdgeColorThumbnail;
                    } else {
                        thumbnail = exteriorColorThumbnail;
                    }
                } else {
                    if (isEdgeMaterial) {
                        thumbnail = carcaseEdgeColorThumbnail;
                    } else {
                        thumbnail = carcaseColorThumbnail;
                    }
                }

                if (isEdgeMaterial) return <React.Fragment key={field.name} />;

                return (
                    <React.Fragment key={field.name}>
                        {header}
                        <MaterialSimplified
                            isVisible={isVisible}
                            isExterior={isExterior}
                            thumb={thumbnail}
                            hasDoor={hasDoor}
                            field={field}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};
