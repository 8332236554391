import React from 'react';
import {Icon, useQueryParams} from 'shared/helpers';
import {ProductProvider} from 'contexts';
import {CategoriesAndItems, Search} from 'shared';
import {Product, Breadcrumbs} from 'components/customer/Product';

import './product.scss';

const Products = () => {
    const {product, cabinetId} = useQueryParams();

    return (
        <div
            className={
                !product ? 'Products product' : 'Products product bottomMargin'
            }>
            <h1>
                <Icon iconName="Headers-Add-Product.svg" />
                <label>{cabinetId ? 'Edit' : 'Add'} Product</label>
            </h1>

            <section className="product-content">
                <div className="search-info">
                    <Breadcrumbs />
                </div>
                <div>
                    {!product ? (
                        <div className="productSearch">
                            <label>Product Search:</label>

                            <div className="productSearchField">
                                <Search />
                            </div>
                        </div>
                    ) : null}

                    <div className="productsAndCategories">
                        {!product ? (
                            <CategoriesAndItems isProduct={true} />
                        ) : (
                            <Product />
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export const ProductWrapper = () => {
    return (
        <ProductProvider>
            <Products />
        </ProductProvider>
    );
};

export default ProductWrapper;
