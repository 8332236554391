import {GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Col, FormLabel} from 'react-bootstrap';
import {validSubpanels} from 'shared/helpers';
import {MoreInfo} from 'shared';
import {FormGroup} from 'shared/components/Forms/FormGroup';
import {cloneDeep} from 'lodash';

// NOTE: i have used eslint-disable-next-line react/no-array-index-key
// to suppress using index as key in this component.
// Need to fix this by using array of objects with unique key rather
// than just array of nunbers for doos subpanels.
export const DoorSubPanels = ({
    fields,
    fieldset,
    isQFP = false,
    selectHandler = false,
}) => {
    const {values, setFieldValue} = useFormikContext();

    const [checkBoxes, setCheckBoxes] = useState();

    const {subPanelField, nonSubPanelFields} = useMemo(() => {
        const subPanelField = fields.find((field) => field.name == 'sub_panel');
        const nonSubPanelFields = fields.filter(
            (field) => field.name !== 'sub_panel'
        );

        return {subPanelField, nonSubPanelFields};
    }, [fields]);

    const {verticalField, horizontalField} = useMemo(() => {
        const horizontalField =
            subPanelField && subPanelField.hasOwnProperty('options')
                ? subPanelField.options.horizontalAmountField
                : 0;
        const verticalField =
            subPanelField && subPanelField.hasOwnProperty('options')
                ? subPanelField.options.verticalAmountField
                : 0;

        return {horizontalField, verticalField};
    }, [subPanelField]);

    const getValidatedAmount = useCallback((values, field) => {
        const value = values[String(field)];
        return typeof value === 'number' && !isNaN(value) ? value : 0;
    }, []);

    const handleChange = useCallback(
        (i, j) => (event) => {
            const checked = event.target.checked;
            let panels = cloneDeep(
                values.glassSubPanels ? values.glassSubPanels : [[false]]
            );

            panels = validSubpanels(panels);

            panels[Number(i)][Number(j)] = checked;

            if (selectHandler) selectHandler('glassSubPanels', panels);
            else setFieldValue('glassSubPanels', panels);
        },
        [setFieldValue, selectHandler, values]
    );

    const getValidatedGlassSubPanel = useCallback(
        (i, j) => {
            if (
                Array.isArray(values.glassSubPanels) &&
                Array.isArray(values.glassSubPanels[Number(i)]) &&
                typeof values.glassSubPanels[Number(i)][Number(j)] === 'boolean'
            ) {
                return values.glassSubPanels[Number(i)][Number(j)];
            }
            return false;
        },
        [values]
    );

    useEffect(() => {
        if (horizontalField && verticalField) {
            let horizontalAmount = getValidatedAmount(values, horizontalField);
            let verticalAmount = getValidatedAmount(values, verticalField);

            horizontalAmount = horizontalAmount >= 3 ? 3 : horizontalAmount + 1;
            verticalAmount = verticalAmount >= 3 ? 3 : verticalAmount + 1;

            const positions = Array(horizontalAmount).fill(
                Array(verticalAmount).fill(true)
            );
            const validSubPanels = validSubpanels(positions);

            setCheckBoxes(validSubPanels);
        }
    }, [
        getValidatedAmount(values, horizontalField),
        getValidatedAmount(values, verticalField),
        values.cabinet_type ? values.cabinet_type : 0,
    ]);

    return (
        <>
            {checkBoxes ? (
                <>
                    {isQFP ? null : <hr />}
                    <FormGroup controlId="subpanels">
                        <FormLabel column md={5} style={{paddingRight: '24px'}}>
                            {subPanelField.displayName}
                            {subPanelField.hasOwnProperty('options') &&
                            subPanelField.options.hasOwnProperty('moreInfo') &&
                            subPanelField.options.moreInfo ? (
                                <MoreInfo
                                    info={subPanelField.options.moreInfo}
                                />
                            ) : null}
                        </FormLabel>
                        <Col>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                {checkBoxes.map((horizontal, i) => {
                                    return (
                                        <div
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={i}
                                            style={{
                                                display: 'flex',
                                                marginBottom: '4px',
                                            }}>
                                            {horizontal.map((subPanel, j) => {
                                                if (subPanel) {
                                                    return (
                                                        <input
                                                            type="checkbox"
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`${i}-${j}`}
                                                            checked={getValidatedGlassSubPanel(
                                                                i,
                                                                j
                                                            )}
                                                            onChange={handleChange(
                                                                i,
                                                                j
                                                            )}
                                                            style={{
                                                                margin: '5px 5px 0px 0px',
                                                            }}
                                                        />
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </FormGroup>
                </>
            ) : null}
            <hr style={{opacity: isQFP ? 0.1 : 1}} />
            <GenericFields
                fields={nonSubPanelFields}
                fieldsetName={fieldset.name}
                fieldsetTitle={fieldset.title}
                fieldsetLayout={isQFP ? '' : 'compound'}
                selectHandler={selectHandler}
                hasFullWidth={isQFP}
                isQFP={isQFP}
            />
        </>
    );
};
