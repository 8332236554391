import {useFormikContext} from 'formik';
import React, {useMemo, useRef} from 'react';
import {Col, Form} from 'react-bootstrap';
import {Drawer} from 'components/customer/Product/entity/Drawer';
import SelectField from 'shared/components/SelectField';
import {FormGroup} from 'shared/components/Forms/FormGroup';

interface DrawerStyleProps {
    runnerIndex: number;
    fieldName: string;
}

export const DrawerStyle = ({runnerIndex, fieldName}: DrawerStyleProps) => {
    const {values} = useFormikContext<{
        drawers: Drawer[];
    }>();
    const options = useRef([
        {
            value: '0',
            label: 'Fixed Height',
        },
        {
            value: '1',
            label: 'Auto Height',
        },
    ]).current;

    const {disabled} = useMemo(() => {
        const drawer = values.drawers[runnerIndex];

        const limit = values.drawers.length;
        const fixedHeightDrawers = values.drawers.filter(
            (drawer) => drawer.drawer_style == 0
        ).length;

        return {
            disabled:
                drawer.drawer_style == 1 && fixedHeightDrawers == limit - 1,
        };
    }, [values]);

    return (
        <FormGroup>
            <Form.Label column xs={5}>
                Style
                <span>*</span>
            </Form.Label>
            <Col xs={7}>
                <SelectField
                    name={`drawers[${runnerIndex}.${fieldName}]`}
                    options={options}
                    isDisabled={disabled}
                />
            </Col>
        </FormGroup>
    );
};
