import React, {useCallback} from 'react';
import styled from 'styled-components';
import {NumberSpinner} from 'components/customer/Product/QuickAdd/NumberSpinner';
import {Width} from 'components/customer/Product/QuickAdd/Width';
import {HardwareButton} from 'shared/components/Product/Item';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';

export const Controls = () => {
    const {open, loading, addProduct} = useQuickAddContext();

    const clickHandler = useCallback(() => {
        void addProduct();
    }, [addProduct]);

    return (
        <Container $display={open} $transparent={loading}>
            <NumberSpinner />
            <Width />
            <HardwareButton
                data-cy="quick-add-button"
                $margin={false}
                disabled={loading}
                onClick={clickHandler}
                className="button-blue btn-sm"
                iconName="Button-Add.svg">
                Add
            </HardwareButton>
        </Container>
    );
};

const Container = styled.div<{$display: boolean; $transparent: boolean}>`
    visibility: ${({$display}) => ($display ? 'visible' : 'hidden')};
    opacity: ${({$transparent}) => ($transparent ? 0.5 : 1)};
    padding-top: 5px;
    display: flex;
    height: 35px;
    gap: 5px;
`;
