import {useDrawers} from 'hooks';
import React, {CSSProperties, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import {useAppSelector} from 'store/customer';
import {getHasWebGLError} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {Flex} from 'shared/components/StyledComponents';
import {Preview3DComponentLazy} from 'components/customer/Preview3DCommon/components/Preview3DComponentLazy';

interface DrawerPreviewProps {
    has3DPreview: boolean;
    isAdvanced?: boolean;
}

export const DrawerPreview = ({
    isAdvanced = false,
    has3DPreview,
}: DrawerPreviewProps) => {
    const {position, canvasContainerId} = useDrawers({isAdvanced});
    const hasWebGLError = useAppSelector(getHasWebGLError);

    const [show3DPreview, setShow3DPreview] = useState(true);

    const toggle3DPreviewHandler = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setShow3DPreview((prevState) => !prevState);
            event.currentTarget.blur();
        },
        [position, canvasContainerId]
    );

    return (
        <Flex $alignItems="end" $flexDirection="column" $gap="5px">
            {has3DPreview && !hasWebGLError ? (
                <SwitchTo3DButton
                    variant="primary"
                    onClick={toggle3DPreviewHandler}>
                    Switch to {show3DPreview ? 'Drawer Face View' : '3D View'}
                </SwitchTo3DButton>
            ) : null}

            {has3DPreview && !hasWebGLError ? (
                <ToggleDiv $show={!!show3DPreview}>
                    <Preview3DComponentLazy previewLocation={'DRAWERS'} />
                </ToggleDiv>
            ) : null}

            <ToggleDiv $show={!show3DPreview || !has3DPreview || hasWebGLError}>
                <div
                    style={position as CSSProperties}
                    className="panelContainer"
                    id={canvasContainerId}
                />
            </ToggleDiv>
        </Flex>
    );
};

const SwitchTo3DButton = styled(Button)`
    font-size: 0.75rem;
    font-weight: 500;
    background: rgb(var(--secondary_colour)) !important;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
        background-color: rgb(var(--primary_colour)) !important;
    }
    &:focus,
    &:active,
    &:focus-visible {
        background-color: rgb(var(--primary_colour)) !important;
        box-shadow: none;
    }
`;

const ToggleDiv = styled.div<{$show?: boolean; $opacityToggle?: boolean}>`
    ${({$show}) => (!$show ? 'display: none;' : '')}
    min-width: 100%;
`;
