import {AppApi} from 'store/customer/api';
import {
    Product,
    ProductForm,
    HardwareOption,
    HingeStyle,
    ProductDefault,
    ValidStructureInterface,
} from 'components/customer/Product/entity';
import {getValidStructure} from 'hooks/QFP/Shared';
import {mapBooleans} from 'components/customer/QFPRedux/helpers/mapBooleans';
import {mapHiddenMaterials} from 'components/customer/Product/helpers/mapHiddenMaterials';
import {FingerPullStyle} from 'components/customer/Product/entity/FingerPullStyle';
import {
    Appliance,
    ApplianceDB,
} from 'components/customer/Product/entity/Appliance';
import {getApplianceType} from 'shared/components/Forms/Applicance';
import {ProductApi as ProductApiInterface} from 'components/customer/Product/entity/ProductApi';
import {mapProduct} from 'components/customer/Product/helpers/mapProduct';
import {mapMaterial} from 'components/customer/Materials/helper/materialHelper';

export const ProductApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getProductData: builder.query<
            Product,
            {
                cabinetType?: number;
                roomId?: number;
                cabinetId?: number;
                materialId?: number;
                edgeId?: number;
                doorId?: number;
                doorFilter?: string;
            }
        >({
            query: ({
                cabinetType,
                roomId,
                cabinetId,
                materialId,
                edgeId,
                doorId,
                doorFilter,
            }) => {
                if (cabinetId) {
                    return `cabinets/jobcabinet/id/${cabinetId}`;
                }

                const searchParams: {
                    material_id?: string;
                    edge_id?: string;
                    door_id?: string;
                    door_filter?: string;
                } = {};

                if (typeof materialId !== 'undefined') {
                    searchParams.material_id = String(materialId);
                }

                if (typeof edgeId !== 'undefined') {
                    searchParams.edge_id = String(edgeId);
                }

                if (typeof doorId !== 'undefined') {
                    searchParams.door_id = String(doorId);
                }

                if (typeof doorFilter !== 'undefined') {
                    searchParams.door_filter = doorFilter;
                }

                const search =
                    Object.keys(searchParams).length > 0
                        ? '?' + new URLSearchParams(searchParams).toString()
                        : '';

                return `cabinets/jobcabinet/cabinetType/${cabinetType}/roomId/${roomId}${search}`;
            },
            transformResponse: (response: {job_cabinet: Product}) =>
                mapHiddenMaterials(response.job_cabinet),
            providesTags: (result, error, props) => [
                {type: 'ProductDefaultRoom', ...props},
            ],
        }),
        getProductDefault: builder.query<ProductDefault, {cabinetType: number}>(
            {
                query: ({cabinetType}) => `cabinets/formfields/${cabinetType}`,
                transformErrorResponse: (response) => response,
                providesTags: (result, error, {cabinetType}) => [
                    {type: 'ProductDefault', cabinetType},
                ],
            }
        ),
        getProductStructure: builder.query<ProductForm, {cabinetType: number}>({
            query: ({cabinetType}) => `cabinets/data/fields/${cabinetType}`,
            transformResponse: (response: ProductForm) => response,
            providesTags: (result, error, {cabinetType}) => [
                {type: 'ProductStructure', cabinetType},
            ],
        }),
        getQFPProductStructure: builder.query<
            ValidStructureInterface,
            {cabinetType: number}
        >({
            query: ({cabinetType}) => {
                if (typeof cabinetType === 'undefined') {
                    throw new Error('Please provide cabinet type');
                }

                return `cabinets/data/fields/${cabinetType}`;
            },
            transformResponse: (response: ProductForm) => {
                const validStructure = getValidStructure(response.structure);
                validStructure.validation = response.validation;

                validStructure.validation.push({
                    fields: ['custom_colour'],
                    message: 'Please enter custom color value',
                    rule: 'LEN(custom_colour) > 0',
                    enabled:
                        'AND(cabinet_ext_colour.customColour, hasCustomColour)',
                    type: 'single',
                });
                validStructure.originalStructure = response.structure;
                validStructure.majorDimensions = response.majorDimensions;

                return mapBooleans(validStructure);
            },
            providesTags: (result, error, {cabinetType}) => [
                {type: 'QFPProductStructure', cabinetType},
            ],
        }),
        getHardwareOptions: builder.query<HardwareOption[], void>({
            query: () => 'customer/manufacturerHardwareOptions',
            transformResponse: (response: {
                hardware_options: HardwareOption[];
            }) => response.hardware_options,
            providesTags: [{type: 'HardwareOptions'}],
        }),
        getHingeStyles: builder.query<HingeStyle[], {cabinetType: number}>({
            query: ({cabinetType}) => `hingeStyle/cabinet/${cabinetType}`,
            transformResponse: (response: {hingeStyles: HingeStyle[]}) =>
                response.hingeStyles,
            providesTags: (result, error, {cabinetType}) => [
                {
                    type: 'HingeStyles',
                    cabinetType,
                },
            ],
        }),
        getCabinets: builder.query<Product[], {roomId: number}>({
            query: ({roomId}) => `jobcabinets/room/${roomId}/qfp`,
            transformResponse: (response: {
                existing_quick_flat_products: Product[];
            }) => {
                return response.existing_quick_flat_products.sort(
                    (a, b) => a.room_cab_number - b.room_cab_number
                );
            },
            providesTags: (response, error, {roomId}) => [
                {type: 'Cabinets', roomId},
            ],
        }),
        deleteCabinet: builder.mutation<void, {cabinetId: number}>({
            query: ({cabinetId}) => ({
                url: `cabinets/jobcabinet/${cabinetId}`,
                method: 'DELETE',
            }),
            // NOTE: Not invalidating these here as qfp will save multiple
            // products and will probably will make multiple network request
            // invalidatesTags: ['Cabinets', 'JobCost', 'ProductRoom'],
        }),
        deleteCabinets: builder.mutation<
            void,
            {jobId: number; cabinetIds: number[]}
        >({
            query: ({jobId, cabinetIds}) => {
                const queryString = cabinetIds
                    .map((id) => `jobCabinetIds[]=${id}`)
                    .join('&');

                return {
                    url: `jobs/${jobId}/jobCabinets?${queryString}`,
                    method: 'DELETE',
                };
            },
        }),
        saveProduct: builder.mutation<
            {id: number; roomCabNumber: number},
            {product: Product}
        >({
            query: ({product}) => ({
                url: 'cabinets/jobcabinet/save',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(product),
            }),
            transformResponse: (response: {
                id: number;
                roomCabNumber: number;
            }) => ({id: response.id, roomCabNumber: response.roomCabNumber}),
            invalidatesTags: ['Variations', 'JobCabinetVariationDetails'],
            // NOTE: Not invalidating these here as qfp will save multiple
            // products and will probably will make multiple network request
            // invalidatesTags: ['JobCost', 'ProductRoom'],
        }),
        getRoomProducts: builder.query<Product[], {roomId: number}>({
            query: ({roomId}) => `room/${roomId}`,
            transformResponse: (response: {
                job_cabinets: ProductApiInterface[];
            }) => {
                if (response && response.job_cabinets) {
                    return response.job_cabinets
                        .map(mapProduct)
                        .map((product) => ({
                            ...product,
                            extMaterial: mapMaterial(product.ext_material),
                            carcMaterial: mapMaterial(product.carc_material),
                        }))
                        .sort((a, b) => a.room_cab_number - b.room_cab_number);
                }

                return [];
            },
            providesTags: (response, error, {roomId}) => [
                {type: 'ProductRoom', roomId},
            ],
        }),
        updateProductQuantity: builder.mutation<
            void,
            {cabinetId: number; quantity: number}
        >({
            query: ({cabinetId, quantity}) => ({
                url: `jobcabinet/${cabinetId}/quantity/${quantity}`,
                method: 'PUT',
            }),
            invalidatesTags: ['JobCost', 'ProductRoom'],
        }),
        removeProductVariations: builder.mutation<
            void,
            {
                data: {
                    jobCabinetIds?: number[];
                    benchIds?: number[];
                };
            }
        >({
            query: ({data}) => ({
                url: `jobcabinets/variations/remove`,
                method: 'POST',
                body: {
                    data: data,
                },
            }),
            invalidatesTags: [
                'Variations',
                'BTMBenches',
                'JobCabinetVariationDetails',
            ],
        }),
        updateOrder: builder.mutation<
            void,
            {jobId: number; roomId: number; order: {[key: number]: number}}
        >({
            query: ({jobId, roomId, order}) => ({
                url: `jobs/${jobId}/room/${roomId}/reorder`,
                method: 'POST',
                body: order,
            }),
            invalidatesTags: ['ProductRoom'],
        }),
        resetKeys: builder.mutation<void, {jobId: number}>({
            query: ({jobId}) => ({
                url: `jobs/${jobId}/reset_keys`,
                method: 'POST',
            }),
        }),
        getFingerPullStyles: builder.query<
            FingerPullStyle[],
            {drawerGap?: number; topMargin?: number}
        >({
            query: () => 'fingerpull',
            transformResponse: (
                response: {fingerPull: FingerPullStyle[]},
                _,
                {drawerGap = 0, topMargin = 0}
            ) => {
                const result = response.fingerPull;

                result.unshift({id: -1, name: 'None', drawerGap, topMargin});

                return result;
            },
            providesTags: ['FingerPullStyles'],
        }),
        getAppliances: builder.query<Appliance[], void>({
            query: () => 'customer/kitchen-appliances',
            transformResponse: (response: {data: ApplianceDB[]}) => {
                return response.data.map((appliance) => ({
                    id: appliance.id,
                    name: `${appliance.make}: ${appliance.model}; H:${appliance.height} x W:${appliance.width} x D:${appliance.depth}`,
                    image: appliance.imageName
                        ? `uploads/images/${getApplianceType(appliance.type)}/${
                              appliance.imageName
                          }`
                        : null,
                    type: appliance.type,
                    height: appliance.height,
                    width: appliance.width,
                    depth: appliance.depth,
                }));
            },
            providesTags: ['Appliances'],
        }),
        addAppliance: builder.mutation<number, ApplianceDB>({
            query: (body) => ({
                url: 'customer/kitchen-appliances/save',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Appliances'],
            transformResponse: (result: {data: number}) => {
                return result.data;
            },
        }),
    }),
});

export const invalidateJobAndCabinet = () =>
    ProductApi.util.invalidateTags(['Job', 'Cabinets']);
export const invalidateSidebarAndCost = () =>
    ProductApi.util.invalidateTags(['JobCost', 'ProductRoom']);
export const invalidateProductAndCost = () =>
    ProductApi.util.invalidateTags([
        'Job',
        'JobCost',
        'ProductRoom',
        'Cabinets',
    ]);
export const invalidateProductDefaultByRoom = () =>
    ProductApi.util.invalidateTags(['ProductDefaultRoom']);
export const invalidateProductDefault = () =>
    ProductApi.util.invalidateTags(['ProductDefault']);

export const {
    useLazyGetProductDataQuery,
    useLazyGetProductStructureQuery,
    useGetHardwareOptionsQuery,
    useGetHingeStylesQuery,
    useLazyGetProductDefaultQuery,
    useGetProductDefaultQuery,
    useLazyGetQFPProductStructureQuery,
    useGetQFPProductStructureQuery,
    useLazyGetCabinetsQuery,
    useDeleteCabinetMutation,
    useSaveProductMutation,
    useGetRoomProductsQuery,
    useUpdateProductQuantityMutation,
    useUpdateOrderMutation,
    useResetKeysMutation,
    useGetProductStructureQuery,
    useRemoveProductVariationsMutation,
    useLazyGetFingerPullStylesQuery,
    useLazyGetAppliancesQuery,
    useAddApplianceMutation,
} = ProductApi;
