import React, {useEffect} from 'react';
import {Search} from 'components/customer/Materials/Search';
import {MaterialFormFieldOptions} from 'shared/types/materials';
import {MaterialType} from 'components/customer/Materials/entity';
import {useAppDispatch} from 'store/customer';
import {hasDoorSet} from 'components/customer/Materials/store/materialSlice';
import AdvancedMaterials from 'components/customer/AdvancedMaterials/AdvancedMaterials';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';

interface MaterialSimplifiedType {
    isVisible?: boolean;
    isExterior?: boolean;
    thumb: JSX.Element;
    hasDoor: {
        ext: boolean;
        carc: boolean;
    };
    field: MaterialFormFieldOptions;
}

export const MaterialSimplified = ({
    isExterior = true,
    isVisible = true,
    thumb,
    hasDoor,
    field,
}: MaterialSimplifiedType): JSX.Element => {
    const dispatch = useAppDispatch();
    const [query] = useSearchParams();

    useEffect(() => {
        if (isExterior) {
            dispatch(hasDoorSet(hasDoor.ext));
        }
    }, [hasDoor]);

    return (
        <section
            style={{
                marginBottom: '15px',
            }}
            className={!isVisible ? 'hidden_field' : ''}>
            <MaterialsContainer>
                <ThumbContainer>
                    {thumb}
                    <ColorBrowserButtonContainer>
                        <ProductAdvancedMaterials
                            materialType={
                                isExterior
                                    ? MaterialType.EXTERIOR
                                    : MaterialType.CARCASE
                            }
                            cabinetType={
                                // NOTE: This won't work for QFP. update it to use
                                // proper cabinet type from individual products.
                                query.has('product')
                                    ? Number(query.get('product'))
                                    : null
                            }
                            hasDoors={isExterior ? hasDoor.ext : hasDoor.carc}
                        />
                    </ColorBrowserButtonContainer>
                </ThumbContainer>
                <SearchContainer>
                    <Search
                        field={field}
                        hasDoor={isExterior ? hasDoor.ext : hasDoor.carc}
                        materialType={
                            isExterior
                                ? MaterialType.EXTERIOR
                                : MaterialType.CARCASE
                        }
                    />
                </SearchContainer>
            </MaterialsContainer>
        </section>
    );
};

const ThumbContainer = styled.div`
    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: center;
        align-items: baseline;
        margin-bottom: 15px;
    }
`;

const SearchContainer = styled.div`
    flex-grow: 1;
`;

const ProductAdvancedMaterials = styled(AdvancedMaterials)`
    min-width: 150px;
`;

const MaterialsContainer = styled.div`
    display: flex;
    gap: 0 15px;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        flex-direction: column;
    }
`;

const ColorBrowserButtonContainer = styled.div`
    margin-top: 25px;
`;
